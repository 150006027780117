import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { APP_ROUTES } from "../application/Router/constants/AppRoutes";
import Call from "./userVideoCall";
import {
  AddPrescription,
  getDiagnosis,
} from "../Redux/Actions/MedicineActions";
import LabModal from "./LabModal";
import DrugModal from "./DrugModal";
import ReferModal from "./ReferModal";
import DatePicker from "react-datepicker";
import moment from "moment";
import Autocomplete from "react-autocomplete";
import referto from "../Assets/Images/refer-to.svg";
import prescription1 from "../Assets/Images/prescription.png";
import stethoscope from "../Assets/Images/stethoscope.svg";
import pills from "../Assets/Images/pills2.svg";
import diagnostics from "../Assets/Images/diagnostics2.svg";
import endcall from "../Assets/Images/end.svg";
import videocall from "../Assets/Images/videoconsult.svg";
import { PhoneIcon } from "@heroicons/react/outline";
import phone from "../Assets/Images/phone@2x.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RequestAccess from "./RequestAccess";
import Share from "./Share";
import { encodeBase64File } from "../helper/filebase64";
import vc from "../Assets/Images/video.png";
import qc from "../Assets/Images/quick.png";
import ac from "../Assets/Images/audio.png";
import {
  MinusIcon,
  PlusIcon,
  SearchIcon,
  EyeOffIcon,
  EyeIcon,
  ArrowsExpandIcon,
  ExternalLinkIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import shareImg from "../Assets/Images/share.png";
import { Modal, CircularProgress } from "@material-ui/core";
import MinimizeVideoCall from "./MinimizeVideoCall";
import {
  SaveVideoCall,
  DeleteVideoUrl,
} from "../Redux/Actions/videoCallActions";
import Draggable from "react-draggable";
import patientService from "../Redux/services/patientService";
import priscription from "../Redux/services/priscription";
import ConsulatationReport from "./ConsultationReport";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import NavigationPrompt from "react-router-navigation-prompt";
import RequestEndConsultation from "./RequestEndConsultation";
import Consulatationicon from "../Assets/Images/consultation.jpg";
import scanicon from "../Assets/Images/scan-ico.svg";
import cameraIcon from "../Assets/Images/camera_icon.svg";
import videoCallIcon from "../Assets/Images/video_call.svg";
import micIcon from "../Assets/Images/mic_icon.svg";
import micOffIcon from "../Assets/Images/mic_off_icon.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

// Agora imports
import AgoraRTC from "agora-rtc-sdk-ng";
import {
  LocalUser,
  RemoteUser,
  useIsConnected,
  useJoin,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  usePublish,
  useRemoteUsers,
} from "agora-rtc-react";

const USE_AGORA = false;

function VideoCall(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  let inputRef;

  const userData = useSelector((state) => state.authReducer.userData);
  const { state } = location;

  const { callurl, callType, eventLocation } = useSelector(
    (state) => state.videoCall
  );
  const datas = useSelector((state) => state.medicinedruglist);
  const { isLoading, diagnosisList } = datas;

  // Common states
  const [list, setList] = useState([]);
  const [editMode, seteditMode] = useState(false);
  const [openDrug, setDrugModal] = useState(false);
  const [openPreview, setopenPreview] = useState(false);
  const [openLab, setLabModal] = useState(false);
  const [openRefer, setReferModal] = useState(false);
  const [dates, setDate] = useState(moment().format("MM/DD/yyyy"));
  const [showRequestAccess, setShowRequestAccess] = useState(false);
  const [showRequestAccessConsulation, setShowRequestAccessConsultation] =
    useState(false);
  const [share, setShare] = useState(false);
  const [prescriptionDoc, setprescriptionDoc] = useState(null);
  const [docType, setdocType] = useState(null);
  const [fileName, setfileName] = useState("");
  const hiddenFileInput = useRef(null);
  const [isCalldisabled, setIsCalldisabled] = useState(false);
  const [promptClick, setPromptClick] = useState(true);
  const [visitData, setVisitData] = useState({});
  const [patientDrugPrescriptionList, sepatientDrugPrescriptionList] = useState(
    []
  );
  const [patientLabTestsList, setpatientLabTestsList] = useState([]);
  const [val1, setVal1] = useState("");
  const [val2, setVal2] = useState("");
  const [val3, setVal3] = useState("");
  const [val4, setVal4] = useState("");
  const [showvideo, setshowvideo] = useState(false);
  const [showaudio, setshowaudio] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [click, setClick] = useState(false);
  const [preview, setPreview] = useState();
  const [min, setMin] = useState(false);
  const [minimize, setMinimize] = useState(true);
  const [closePatient, setClosePatient] = useState(true);
  const [urlPat, setUrlPat] = useState();
  const [selectedLabTest, setSelectedLabTest] = useState([]);
  const [symptomsList, setsymptomsList] = useState([]);
  const [selectsymptomsList, setselsymptomsList] = useState([]);
  const [eyeWearUser, setEyeWearUser] = useState(false);
  const [surgeryEye, setSurgeryEye] = useState(false);
  const [surgeryValue, setSurgeryValue] = useState("");
  const [agoraFeatureFlag, setAgoraFeatureFlag] = useState(false);

  // Eye reading states
  const [reading1RE, setReReading1] = useState({
    createdDate: "",
    createdBy: userData.code,
    modifiedBy: userData.code,
    modifiedDate: "",
    status: 1,
    reading: "Reading 1",
  });
  const [reading2RE, setReReading2] = useState({
    createdDate: "",
    createdBy: userData.code,
    modifiedBy: userData.code,
    modifiedDate: "",
    status: 1,
    reading: "Reading 2",
  });
  const [reading3RE, setReReading3] = useState({
    createdDate: "",
    createdBy: userData.code,
    modifiedBy: userData.code,
    modifiedDate: "",
    status: 1,
    reading: "Reading 3",
  });
  const [readingFinalRE, setReReadingFinal] = useState({
    createdDate: "",
    createdBy: userData.code,
    modifiedBy: userData.code,
    modifiedDate: "",
    status: 1,
    reading: "Final_Right",
  });
  const [reading1LE, setLeReading1] = useState({
    createdDate: "",
    createdBy: userData.code,
    modifiedBy: userData.code,
    modifiedDate: "",
    status: 1,
    reading: "Reading 1",
  });
  const [reading2LE, setLeReading2] = useState({
    createdDate: "",
    createdBy: userData.code,
    modifiedBy: userData.code,
    modifiedDate: "",
    status: 1,
    reading: "Reading 2",
  });
  const [reading3LE, setLeReading3] = useState({
    createdDate: "",
    createdBy: userData.code,
    modifiedBy: userData.code,
    modifiedDate: "",
    status: 1,
    reading: "Reading 3",
  });
  const [readingFinalLE, setLeReadingFinal] = useState({
    createdDate: "",
    createdBy: userData.code,
    modifiedBy: userData.code,
    modifiedDate: "",
    status: 1,
    reading: "Final_Left",
  });

  const inputPrimaryDiagnosisRef = useRef(null);
  const inputPrimaryDiagnosisCodeRef = useRef(null);
  const inputSecondryDiagnosisRef = useRef(null);
  const inputSecondryDiagnosisCodeRef = useRef(null);

  const [prescription, setPrescription] = useState({
    createdBy: userData.code,
    diagnositicNotes: "string",
    modifiedBy: userData.code,
    patientId: state?.patientId,
    quantity: 10,
    status: 1,
    userId: userData.code,
    visitId: state.id,
  });

  if (!state?.id) {
    history.push("/");
  }

  // Agora related states and constants
  const appId = "8a19d64688084774b82663baf77b3e2f";
  const [micOn, setMicOn] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const [token, setToken] = useState("");
  const [uId, setUId] = useState("");
  const [channel, setChannel] = useState("");
  const [calling, setCalling] = useState(false);
  const isConnected = useIsConnected();
  const { localCameraTrack } = useLocalCameraTrack(
    cameraOn && agoraFeatureFlag
  );
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn, {
    options: {
      echoCancellation: true, // help reduce echo from remote side
    },
  });
  usePublish(agoraFeatureFlag ? [localMicrophoneTrack, localCameraTrack] : []);
  useJoin(
    agoraFeatureFlag
      ? {
          appid: appId,
          channel: channel,
          token: token ? token : null,
          uid: uId,
        }
      : {},
    agoraFeatureFlag ? calling : false
  );
  const remoteUsers = useRemoteUsers();

  const toggleMic = async () => {
    if (localMicrophoneTrack) {
      // Enable/disable local mic track
      await localMicrophoneTrack.setEnabled(!micOn);
      setMicOn((prev) => !prev);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    patientService.getmysymptomsList().then((res) => {
      setsymptomsList(res.data);
    });
    patientService.getUserSpList(userData.code).then((ress) => {
      if (ress.data[0].userSpecialityList) {
        let userSpecialist = ress.data[0].userSpecialityList.filter(
          (x) => x.speciality === "Optometrist"
        );
        if (userSpecialist.length !== 0) {
          setEyeWearUser(true);
        }
      }
    });

    // Setting urlPat based on environment variables
    if (
      process.env.REACT_APP_IMG_BASEURL ===
      "https://storage.cloud.google.com/curebay-nonprod-application-data/"
    ) {
      setUrlPat(
        "https://patients-stage.curebay.in/post_consultation?q=download&"
      );
    } else if (
      process.env.REACT_APP_IMG_BASEURL ===
      "https://storage.googleapis.com/curebay-prod-application-data/"
    ) {
      setUrlPat("https://patient.curebay.com/post_consultation?q=download&");
    } else {
      setUrlPat(
        "https://patients-stage.curebay.in/post_consultation?q=download&"
      );
    }

    if (state) {
      let payload = state?.id ? state.id : state;
      patientService.getPostConsultation(payload).then((res) => {
        if (res.data && res.data.length) {
          let symptomseEdit =
            res.data[0].symptomsName && res.data[0].symptomsName.split(",");
          setVisitData(res.data[0]);
          sepatientDrugPrescriptionList(
            res.data[0].patientDrugPrescriptionList
          );
          setpatientLabTestsList(res.data[0].patientLabTestsList);
          setselsymptomsList(symptomseEdit);
          seteditMode(true);
          //eyewear_data
          if (res.data[0].eyeReadingMain) {
            const leftEyeData = res.data[0].eyeReadingMain.leftEye;
            const rightEyeData = res.data[0].eyeReadingMain.rightEye;
            setLeReadingFinal(leftEyeData.finalReading);
            setLeReading1(leftEyeData.readingList[0]);
            setLeReading2(leftEyeData.readingList[1]);
            setLeReading3(leftEyeData.readingList[2]);

            setReReadingFinal(rightEyeData.finalReading);
            setReReading1(rightEyeData.readingList[0]);
            setReReading2(rightEyeData.readingList[1]);
            setReReading3(rightEyeData.readingList[2]);
          }
        } else {
          let payload2 = state?.id ? state.id : state;
          patientService.getPreConsultationIllness(payload2).then((resp) => {
            if (resp.data && resp.data.length) {
              setVisitData({
                appointmentId: state?.id,
                complaints: "complaints",
                consultReason: "",
                consultationType: state?.consultationsType,
                createdBy: userData.code,
                userId: userData.code,
                modifiedBy: userData.code,
                diagnostic: "string",
                documentType: "string",
                entryType: state?.consultationsType,
                patientDocument: "c3RyaW5n",
                followUpVisitDate: "",
                primaryDiagnosis: "",
                primarySymptoms: "",
                recommendation: "",
                roleCode: "string",
                saveStatus: "",
                secondaryDiagnosis: "",
                secondarySymptoms: "",
                serviceProvided: "serviceProvided",
                status: 1,
                patientId: state?.patientId,
                symptoms: "",
                site: resp.data[0].site,
                onset: resp.data[0].onSet,
                characteristics: resp.data[0].characteristics,
                radiation: resp.data[0].radiation,
                associatedSymptoms: resp.data[0].associatedSymptoms,
                timing: resp.data[0].timing,
                exacerbatingFactors: resp.data[0].exacerbatingFactors,
                relievingFactors: resp.data[0].relievingFactors,
                treatmentTaken: resp.data[0].treatmentTaken,
                comments: resp.data[0].comments,
                severity: resp.data[0].severity,
                observation: resp.data[0].observation,
                frame: resp.data[0].frame,
                coating: resp.data[0].coating,
                nvAssessment: resp.data[0].nvAssessment,
                dvAssessment: resp.data[0].dvAssessment,
                treatmentOption: resp.data[0].treatmentOption,
                surgeryRecommendation: resp.data[0].surgeryRecommendation,
                visitDate: moment(
                  state.whenAppointment + " " + state.fromTime
                ).format("yyyy-MM-DD HH:mm:ss"),
                visitSummary: "string",
                visitType: state?.consultationsType,
              });
            } else {
              setVisitData({
                appointmentId: state?.id,
                complaints: "complaints",
                consultReason: "",
                consultationType: state?.consultationsType,
                createdBy: userData.code,
                userId: userData.code,
                modifiedBy: userData.code,
                diagnostic: "string",
                documentType: "string",
                entryType: state?.consultationsType,
                patientDocument: "c3RyaW5n",
                followUpVisitDate: "",
                primaryDiagnosis: "",
                primarySymptoms: "",
                recommendation: "",
                roleCode: "string",
                saveStatus: "",
                secondaryDiagnosis: "",
                secondarySymptoms: "",
                serviceProvided: "serviceProvided",
                status: 1,
                patientId: state?.patientId,
                symptoms: "",
                onset: "",
                bodyPartLocation: "",
                duration: "",
                characteristics: "",
                aggravatingFactors: "",
                relievingFactors: "",
                treatmentTaken: "",
                adverseEffect: "",
                severity: "",
                frame: "",
                coating: "",
                nvAssessment: "",
                dvAssessment: "",
                treatmentOption: "",
                surgeryRecommendation: "",
                visitDate: moment(
                  state.whenAppointment + " " + state.fromTime
                ).format("yyyy-MM-DD HH:mm:ss"),
                visitSummary: "string",
                visitType: state?.consultationsType,
              });
            }
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!agoraFeatureFlag) {
      // Only fetch maggie plus link if we are not using Agora
      getLinkMaggie();
    }
    dispatch(getDiagnosis());
    history.push({ pathname: APP_ROUTES.VIDEO_CALL, state: location.state });
    window.onpopstate = function (event) {
      history.go(1);
    };
    return () => {
      localStorage.removeItem("callURL");
      localStorage.removeItem("callTYPE");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (diagnosisList.length) setList(diagnosisList.slice(0, 400));
  }, [diagnosisList.length]);

  useEffect(() => {
    if (isLoading === false && click) {
      history.push({
        pathname: APP_ROUTES.POST_CONSULTATION,
        state: state.id,
      });
    }
  }, [isLoading]);

  const getLinkMaggie = () => {
    const header = {
      secretKey: "CcJYfbsgItHpTQPFr5lg",
    };
    const data = {
      clientCode: "CCM@@202!",
      userType: "HOST",
      meetingKey: state && state.id ? state.id : "",
      memberName: "Dr. " + userData.firstName,
      memberEmail: userData.email,
      memberMobile: userData.mobile,
    };
    axios
      .post(
        "https://cb.maggieplus.com/admin-api/client/meeting/authentication",
        data,
        {
          headers: header,
        }
      )
      .then((result) => {
        if (state?.consultationsType === "A") {
          state.callUrl = result.data.response.url + "/1";
        } else {
          state.callUrl = result.data.response.url;
        }
      })
      .catch((err) => {
        console.log("Err", err);
      });
  };

  const setupAgora = async () => {
    try {
      const channelId = state?.id ? String(state.id) : "defaultChannel";
      const sanitizedChannelId = channelId.replace(/[^a-zA-Z0-9_-]/g, "");

      const response = await axios.get(
        `${process.env.REACT_APP_NEWBASEURL}token/generate?channelName=${sanitizedChannelId}&role=subscriber`
      );
      const tok = response.data?.data?.token;
      const uuid = response.data?.data?.uid;
      if (!tok || !appId || !sanitizedChannelId) {
        throw new Error("App ID, Token, or Channel ID is undefined");
      }
      sessionStorage.removeItem("joinCode");
      sessionStorage.removeItem("consultationType");
      setToken(tok);
      setChannel(sanitizedChannelId);
      setUId(uuid);
    } catch (error) {
      console.error("Error setting up Agora:", error);
    }
  };

  const flagFeature = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEWBASEURL}agoraInfo/get`
      );

      if (response) {
        console.log(response?.data[5], "Response");
        if (response?.data[5]?.isAgora === true) {
          setAgoraFeatureFlag(true);
        } else {
          setAgoraFeatureFlag(false);
        }
      } else {
        console.error("Token or UUID is missing in the response");
      }
    } catch (error) {
      console.error("Error setting up Agora:", error);
    }
  };

  useEffect(() => {
    flagFeature();
  }, []);
  useEffect(() => {
    if (agoraFeatureFlag && token && channel && uId) {
      setCalling(true);
    }
  }, [token, channel, uId, agoraFeatureFlag]);

  const previewClick = () => {
    let data = visitData;
    data["patientLabTestsList"] = patientLabTestsList;
    patientDrugPrescriptionList &&
      patientDrugPrescriptionList.forEach((element) => {
        if (!element.drugName && !element.drugName1) {
          const index = patientDrugPrescriptionList.indexOf(element);
          if (index > -1) {
            patientDrugPrescriptionList.splice(index, 1);
          }
        } else {
          element.drugName = element.drugName
            ? element.drugName
            : element.drugName1;
        }
      });
    data["patientDrugPrescriptionList"] = patientDrugPrescriptionList;
    data["symptomsList"] = selectsymptomsList ? selectsymptomsList : [];
    let readingListLE = [];
    readingListLE.push(reading1LE);
    readingListLE.push(reading2LE);
    readingListLE.push(reading3LE);
    for (var i = 0; i < readingListLE.length; i++) {
      readingListLE[i].createdDate = moment().format("YYYY-MM-DD HH:mm:ss");
      readingListLE[1].modifiedDate = moment().format("YYYY-MM-DD HH:mm:ss");
    }
    let readingListRE = [];
    readingListRE.push(reading1RE);
    readingListRE.push(reading2RE);
    readingListRE.push(reading3RE);
    for (var j = 0; j < readingListRE.length; j++) {
      readingListRE[j].createdDate = moment().format("YYYY-MM-DD HH:mm:ss");
      readingListRE[j].modifiedDate = moment().format("YYYY-MM-DD HH:mm:ss");
    }
    data.eyeReadingMain = {
      leftEye: {
        finalReading: readingFinalLE,
        readingList: readingListLE,
      },
      rightEye: {
        finalReading: readingFinalRE,
        readingList: readingListRE,
      },
    };
    setopenPreview(true);
  };

  const requestAccess = () => {
    setShowRequestAccess(true);
  };

  const closeRequestAccess = () => {
    setShowRequestAccess(false);
  };

  const requestedAccess = () => {
    toast("Request sent to patient successfully");
    setShowRequestAccess(false);
  };

  const closeShare = () => {
    setShare(false);
  };

  const saveEPrescription = async () => {
    setPromptClick(true);

    let pushSymptoms = [];
    selectsymptomsList.forEach((element) => {
      pushSymptoms.push({ symptomsName: element });
    });

    let data = visitData;
    if (prescriptionDoc) {
      data.patientDocument = prescriptionDoc;
      data.documentType = docType;
    }
    prescription.diagnositicNotes = visitData.diagnostic
      ? visitData.diagnostic
      : "string";
    data["prescription"] = prescription;
    data["patientLabTestsList"] = patientLabTestsList;
    data["linkType"] = "e-prescription";
    data["patientSourceChannel"] = state?.patientSourceChannel;
    data["symptomsList"] = pushSymptoms;
    data["roleCode"] = "string";
    data["createdBy"] = userData.code;
    data["modifiedBy"] = userData.code;
    patientDrugPrescriptionList.forEach((element) => {
      if (!element.drugName && !element.drugName1) {
        const index = patientDrugPrescriptionList.indexOf(element);
        if (index > -1) {
          patientDrugPrescriptionList.splice(index, 1);
        }
      } else {
        element.drugName = element.drugName
          ? element.drugName
          : element.drugName1;
      }
    });
    data["patientDrugPrescriptionList"] = patientDrugPrescriptionList;
    data["consultReason"] = state.consultationsReason;
    data["consultationType"] = state?.consultationsType;
    data.visitDate = moment(
      state.whenAppointment + " " + state.fromTime
    ).format("yyyy-MM-DD HH:mm:ss");
    data.userId = userData.code;
    data.patientName = state?.patientName;
    data.patientMobileNo = state?.patientMobileNo;
    data.patientId = state?.patientId;
    data["appointmentId"] = state?.id;
    data.status = 1;
    data.url = urlPat + "id=" + state?.id;
    data.linkType = "e-prescription";

    let readingListLE = [];
    readingListLE.push(reading1LE);
    readingListLE.push(reading2LE);
    readingListLE.push(reading3LE);
    for (var i = 0; i < readingListLE.length; i++) {
      readingListLE[i].createdDate = moment().format("YYYY-MM-DD HH:mm:ss");
      readingListLE[1].modifiedDate = moment().format("YYYY-MM-DD HH:mm:ss");
    }
    let readingListRE = [];
    readingListRE.push(reading1RE);
    readingListRE.push(reading2RE);
    readingListRE.push(reading3RE);
    for (var j = 0; j < readingListRE.length; j++) {
      readingListRE[j].createdDate = moment().format("YYYY-MM-DD HH:mm:ss");
      readingListRE[j].modifiedDate = moment().format("YYYY-MM-DD HH:mm:ss");
    }
    data.eyeReadingMain = eyeWearUser
      ? {
          leftEye: {
            finalReading: readingFinalLE,
            readingList: readingListLE,
          },
          rightEye: {
            finalReading: readingFinalRE,
            readingList: readingListRE,
          },
        }
      : null;

    dispatch(DeleteVideoUrl());
    dispatch(AddPrescription(data));
    setClick(true);
    setIsCalldisabled(false);
    redirectTo();
  };

  const redirectTo = () => {
    let config = {
      method: "put",
      url: `${process.env.REACT_APP_NEWBASEURL}call-status/update?action=END&user=DOCTOR&id=${state?.id}`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(config)
      .then((response) => {
        console.log(response, "responseresponsend");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateEPrescription = async () => {
    setPromptClick(true);
    let data = visitData;
    if (prescriptionDoc) {
      data.patientDocument = prescriptionDoc;
      data.documentType = docType;
    }

    patientLabTestsList &&
      patientLabTestsList.forEach((element) => {
        element.visitId = data.id;
        element.prescriptionId = data.prescription.id;
      });
    patientDrugPrescriptionList &&
      patientDrugPrescriptionList.forEach((element) => {
        element.visitId = data.id;
        element.prescriptionVisitId = data.prescription.id;
      });

    data["patientLabTestsList"] = patientLabTestsList;
    patientDrugPrescriptionList &&
      patientDrugPrescriptionList.forEach((element) => {
        if (!element.drugName && !element.drugName1) {
          const index = patientDrugPrescriptionList.indexOf(element);
          if (index > -1) {
            patientDrugPrescriptionList.splice(index, 1);
          }
        } else {
          element.drugName = element.drugName
            ? element.drugName
            : element.drugName1;
        }
      });
    data["patientDrugPrescriptionList"] = patientDrugPrescriptionList;
    data["consultReason"] = state.consultationsReason;
    data.url = urlPat + "id=" + state?.id;
    data.linkType = "e-prescription";
    data["linkType"] = "e-prescription";
    data["patientSourceChannel"] = state?.patientSourceChannel;
    data["roleCode"] = "string";
    let pushSymptoms = [];
    selectsymptomsList &&
      selectsymptomsList.forEach((element) => {
        pushSymptoms.push({ symptomsName: element });
      });
    data["symptomsList"] = pushSymptoms;

    let readingListLE = [];
    readingListLE.push(reading1LE);
    readingListLE.push(reading2LE);
    readingListLE.push(reading3LE);
    for (var i = 0; i < readingListLE.length; i++) {
      readingListLE[i].createdDate = moment().format("YYYY-MM-DD HH:mm:ss");
      readingListLE[1].modifiedDate = moment().format("YYYY-MM-DD HH:mm:ss");
    }
    let readingListRE = [];
    readingListRE.push(reading1RE);
    readingListRE.push(reading2RE);
    readingListRE.push(reading3RE);
    for (var j = 0; j < readingListRE.length; j++) {
      readingListRE[j].createdDate = moment().format("YYYY-MM-DD HH:mm:ss");
      readingListRE[j].modifiedDate = moment().format("YYYY-MM-DD HH:mm:ss");
    }
    data.eyeReadingMain = eyeWearUser
      ? {
          leftEye: {
            finalReading: readingFinalLE,
            readingList: readingListLE,
          },
          rightEye: {
            finalReading: readingFinalRE,
            readingList: readingListRE,
          },
        }
      : null;
    priscription.UpdatePrescription(data).then((res) => {
      setClick(true);
      setIsCalldisabled(false);
      history.push({ pathname: APP_ROUTES.POST_CONSULTATION, state: state.id });
    });
  };

  const onRemove = (i) => {
    let data = [...selectsymptomsList];
    data.splice(i, 1);
    setselsymptomsList(data);
  };

  const onChangeHandlerSurgeryEye = (e) => {
    setSurgeryValue(e.target.value);
    if (e.target.value === "Yes") {
      setSurgeryEye(true);
    } else if (e.target.value === "No") {
      setSurgeryEye(false);
    }
  };

  const onChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "primarySymptoms") {
      var filter = list.filter((x) => x.termCode === value);
      if (filter?.length > 0) {
        setVisitData({
          ...visitData,
          ["primaryDiagnosis"]: filter[0].description,
          ["primarySymptoms"]: value,
        });
        setVal1(filter[0].description);
      } else {
        setVal1("");
      }
    }
    if (name === "secondarySymptoms") {
      var filter2 = list.filter((x) => x.termCode === value);
      if (filter2?.length > 0) {
        setVisitData({
          ...visitData,
          ["secondaryDiagnosis"]: filter2[0].description,
          ["secondarySymptoms"]: value,
        });
        setVal2(filter2[0].description);
      } else {
        setVal2("");
      }
    }
    setVisitData({ ...visitData, [name]: value });
  };

  const addDrugs = (data) => {
    setDrugModal(false);
    data.forEach((element) => {
      if (!element.drugName && !element.drugName1) {
        const index = data.indexOf(element);
        if (index > -1) {
          data.splice(index, 1);
        }
      } else {
        element.drugName = element.drugName
          ? element.drugName
          : element.drugName1;
      }
    });
    sepatientDrugPrescriptionList(data);
  };

  const addLabTest = (data) => {
    setLabModal(false);
    setpatientLabTestsList(data);
  };

  const onChangeHandlerD = (e) => {
    setVisitData({
      ...visitData,
      ["primaryDiagnosis"]: e.description,
      ["primarySymptoms"]: e.termCode,
    });
  };
  const onChangeHandlerD2 = (e) => {
    setVisitData({
      ...visitData,
      ["secondaryDiagnosis"]: e.description,
      ["secondarySymptoms"]: e.termCode,
    });
  };
  const onChangeHandlerD3 = (e) => {
    setVisitData({
      ...visitData,
      ["primarySymptoms"]: e.termCode,
      ["primaryDiagnosis"]: e.description,
    });
  };
  const onChangeHandlerD4 = (e) => {
    setVisitData({
      ...visitData,
      ["secondarySymptoms"]: e.termCode,
      ["secondaryDiagnosis"]: e.description,
    });
  };

  const renderDescription = (state, val) => {
    return state.description.toLowerCase().indexOf(val.toLowerCase()) !== -1;
  };

  const renderTermcode = (state, val) => {
    return state.termCode.toLowerCase().indexOf(val.toLowerCase()) !== -1;
  };

  const joinvideoCall = async () => {
    setshowvideo(true);
    if (agoraFeatureFlag) {
      await setupAgora();
    } else {
      // Maggie plus flow
      dispatch(
        SaveVideoCall({
          callurl: state.callUrl,
          callType: 1,
          upcomingAppointmentDetail: state,
        })
      );
      setIsCalldisabled(true);
    }
  };

  const joinaudioCall = () => {
    setshowaudio(true);
    state.callType = 2;
    if (!agoraFeatureFlag) {
      // Maggie plus audio call
      dispatch(
        SaveVideoCall({
          callurl: state.callUrl,
          callType: state.callType,
          upcomingAppointmentDetail: state,
        })
      );
      setIsCalldisabled(true);
    } else {
      setupAgora();
    }
  };

  const onSetVal = (e, no) => {
    if (no === 1) {
      setVal1(e);
    } else if (no === 2) {
      setVal2(e);
    } else if (no === 3) {
      setVal3(e);
    } else if (no === 4) {
      setVal4(e);
    }
  };

  const openShare = () => {
    setShare(true);
  };
  const clicktype = () => {
    hiddenFileInput.current.click();
  };

  const changeHandler = async (file) => {
    let splitvalue = file.name.split(".");
    if (
      splitvalue[1].toLowerCase() === "pdf" ||
      splitvalue[1].toLowerCase() === "png" ||
      splitvalue[1].toLowerCase() === "jpeg" ||
      splitvalue[1].toLowerCase() === "jpg"
    ) {
      setdocType(splitvalue[1].toLowerCase());
    }
    setfileName(file.name);
    let b64File = await encodeBase64File(file);
    setprescriptionDoc(b64File);
    if (!file || file.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(file);
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
  };

  const clickPop = (status) => {
    if (status === true) {
      localStorage.setItem("videoStatusId", state.id);
    } else {
      localStorage.removeItem("videoStatusId");
    }
  };

  const zoom = (val) => {
    clickPop(false);
    setClosePatient(val);
  };

  const openNewtab = () => {
    if (!agoraFeatureFlag) {
      window.open(state.callUrl, "_blank").focus();
    }
  };

  const focusPrimaryDiagnosis = (e) => {
    inputPrimaryDiagnosisRef.current.focus();
  };

  const focusSecondaryDiagnosis = (e) => {
    inputSecondryDiagnosisRef.current.focus();
  };

  const focusPrimaryDiagnosisCode = (e) => {
    inputPrimaryDiagnosisCodeRef.current.focus();
  };

  const focusSecondaryDiagnosisCode = (e) => {
    inputSecondryDiagnosisCodeRef.current.focus();
  };

  // Reading Handlers
  const onREReading1 = (e) => {
    setReReading1({ ...reading1RE, [e.target.name]: e.target.value });
  };
  const onREReading2 = (e) => {
    setReReading2({ ...reading2RE, [e.target.name]: e.target.value });
  };
  const onREReading3 = (e) => {
    setReReading3({ ...reading3RE, [e.target.name]: e.target.value });
  };
  const onREReadingFinal = (e) => {
    setReReadingFinal({ ...readingFinalRE, [e.target.name]: e.target.value });
  };
  const onLEReading1 = (e) => {
    setLeReading1({ ...reading1LE, [e.target.name]: e.target.value });
  };
  const onLEReading2 = (e) => {
    setLeReading2({ ...reading2LE, [e.target.name]: e.target.value });
  };
  const onLEReading3 = (e) => {
    setLeReading3({ ...reading3LE, [e.target.name]: e.target.value });
  };
  const onLEReadingFinal = (e) => {
    setLeReadingFinal({ ...readingFinalLE, [e.target.name]: e.target.value });
  };

  const getGridClass = () => {
    const totalUsers = remoteUsers.length + 1; // Local + remote
    if (totalUsers === 1) return "grid-cols-1";
    if (totalUsers === 2) return "grid-cols-2";
    if (totalUsers <= 4) return "grid-cols-2 grid-rows-2";
    return "grid-cols-3 grid-rows-2";
  };

  return (
    <>
      <ToastContainer />
      <NavigationPrompt when={!promptClick} renderIfNotActive={true}>
        {({ isActive, onCancel, onConfirm }) => {
          // If promptClick is false, show the RequestEndConsultation prompt
          if (!promptClick) {
            return (
              <RequestEndConsultation
                onClose={() => {
                  onCancel();
                  setPromptClick(true); // reset to hide prompt next time
                }}
                closePopup={() => {
                  onCancel();
                  setPromptClick(true); // reset to hide prompt next time
                }}
                onConfirm={() => {
                  // Call saveEPrescription after user confirms
                  saveEPrescription();
                  setPromptClick(true); // reset
                  onConfirm();
                }}
                appointmentObj={state}
              />
            );
          }
          return null;
        }}
      </NavigationPrompt>
      {isLoading && (
        <div className="flex justify-center items-center absolute left-0 right-0 m-auto">
          <CircularProgress />
        </div>
      )}
      <div className="sm:p-3 md:p-10 sm:relative   sm:min-h-screen">
        <div className="w-4/6">
          <Call
            requestAccess={requestAccess}
            {...state}
            data={visitData}
            clickPop={clickPop}
            closePatient={closePatient}
          />
        </div>
        <div className="lg:flex space-x-0 sm:space-x-4  sm:flex">
          {state?.consultationsType != "I" ? (
            <>
              <div className="lg:w-8/12  ">
                <div className="relative">
                  {showaudio || showvideo ? (
                    <div className="flex font-bold justify-between text-brand-secondary text-xs">
                      {!agoraFeatureFlag && (
                        <a onClick={() => openNewtab()} className="">
                          <ExternalLinkIcon className="w-5 h-5" />
                        </a>
                      )}
                      <a
                        onClick={() => openShare()}
                        className="flex cursor-pointer"
                      >
                        <img src={shareImg} />
                        &nbsp;
                        <span className="mt-auto mb-auto font-medium font-rubik text-md text-left text-sm text-brand-secondary">
                          Share Link
                        </span>
                      </a>
                    </div>
                  ) : null}

                  {showvideo ? (
                    <>
                      {agoraFeatureFlag && showvideo && (
                        <>
                          <div className="room">
                            {isConnected ? (
                              <div
                                className={`grid ${getGridClass()} gap-1 flex-1`}
                                style={{ height: "85vh" }}
                              >
                                <div className="user">
                                  <LocalUser
                                    cameraOn={cameraOn}
                                    micOn={micOn}
                                    videoTrack={localCameraTrack}
                                    // We do NOT call localMicrophoneTrack.play()
                                    // cover={otherUser}
                                  >
                                    <samp className="user-name">You</samp>
                                  </LocalUser>
                                </div>
                                {remoteUsers.map((user) => (
                                  <div className="user" key={user.uid}>
                                    <RemoteUser user={user}>
                                      <samp className="user-name">{`${state.patientName}`}</samp>
                                    </RemoteUser>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="h-screen flex justify-center items-center">
                                <Spin
                                  indicator={<LoadingOutlined spin />}
                                  size="large"
                                />
                              </div>
                            )}
                          </div>
                          {isConnected && (
                            <div className="control">
                              <div className="left-control">
                                {/* MIC BUTTON WITH IMG */}
                                <button className="btn p-2" onClick={toggleMic}>
                                  <img
                                    src={micOn ? micIcon : micOffIcon}
                                    alt={micOn ? "Mic On" : "Mic Off"}
                                    style={{
                                      width: "32px",
                                      height: "32px",
                                      objectFit: "contain", 
                                    }}
                                  />
                                </button>

                                <button
                                  className="btn"
                                  onClick={() => setCamera((a) => !a)}
                                >
                                  <i
                                    className={`i-camera ${
                                      !cameraOn ? "off" : ""
                                    }`}
                                    style={{
                                      display: "inline-flex",
                                      width: "32px",
                                      height: "32px",

                                      alignItems: "center",
                                      justifyContent: "center",

                                      fontSize: "24px",
                                    }}
                                  />
                                </button>
                              </div>

                              <button
                                className={`btn btn-phone ${
                                  calling ? "btn-phone-active" : ""
                                }`}
                                onClick={async () => {
                                  if (localMicrophoneTrack) {
                                    localMicrophoneTrack.stop();
                                    localMicrophoneTrack.close();
                                  }
                                  if (localCameraTrack) {
                                    localCameraTrack.stop();
                                    localCameraTrack.close();
                                  }

                                  setCalling(false);
                                  setIsCalldisabled(true);
                                  setshowvideo(false);
                                }}
                              >
                                {calling ? (
                                  <i className="i-phone-hangup" />
                                ) : (
                                  <i className="i-mdi-phone" />
                                )}
                              </button>
                            </div>
                          )}
                        </>
                      )}
                      <div className="lg:block lg:mt-5 ">
                        <div>
                          <div className="gap-3.5 pr-2 pl-2 flex mb-2">
                            {(state?.consultationsType == "V" ||
                              state?.consultationsType == "Q") &&
                            !editMode ? (
                              <button
                                onClick={() => joinvideoCall()}
                                className="bg-brand-secondary cursor-pointer  float-left text-white font-medium w-1/5 py-2 px-3 h-25 rounded text-xs"
                                disabled={isCalldisabled}
                              >
                                <div className="flex justify-center mb-2">
                                  <img
                                    src={
                                      state?.consultationsType == "Q" ? qc : vc
                                    }
                                  />
                                </div>
                                <p className="hieght-18">Join Video Call</p>
                              </button>
                            ) : null}
                            {state?.consultationsType == "A" && !editMode ? (
                              <button
                                onClick={() => joinaudioCall()}
                                className="bg-brand-secondary cursor-pointer  float-left text-white font-medium w-1/5 py-2 px-3 h-25 rounded text-xs"
                                disabled={isCalldisabled}
                              >
                                <div className="flex justify-center mb-2">
                                  {/* <img src={vc} /> */}
                                  <img src={ac} />
                                </div>
                                <p className="hieght-18">Join Audio Call</p>
                              </button>
                            ) : null}
                            <button
                              onClose={addDrugs}
                              onClick={() => setDrugModal(true)}
                              className="bg-brand-secondary cursor-pointer  float-left text-white font-medium py-2 px-2 w-1/5 h-25 rounded text-[4px] sm:text-xs"
                            >
                              <div className="flex justify-center mb-2">
                                <img src={pills} />
                              </div>
                              <p className="hieght-18">Add Medicine</p>
                            </button>
                            <button
                              onClose={addLabTest}
                              onClick={() => setLabModal(true)}
                              className="bg-brand-secondary cursor-pointer float-left text-white font-medium py-2 px-3 w-1/5 h-25 rounded text-[4px] sm:text-xs"
                            >
                              <div className="flex justify-center mb-2">
                                <img src={diagnostics} />
                              </div>
                              <p className="hieght-18">Add Lab Test</p>
                            </button>
                            <button
                              onClick={() => setReferModal(true)}
                              className="bg-brand-secondary cursor-pointer  float-center text-white font-medium py-2 px-3 w-1/5 h-25 rounded text-xs"
                            >
                              <div className="flex justify-center mb-2">
                                <img src={stethoscope} />
                              </div>
                              <p className="hieght-18">Refer To</p>
                            </button>
                            {editMode ? (
                              <button
                                onClick={updateEPrescription}
                                className={
                                  "cursor-pointer bg-brand-secondary float-left text-white font-medium py-2 px-3 w-1/5 h-25 rounded text-xs"
                                }
                              >
                                <div className="flex justify-center mb-2">
                                  <img src={endcall} />
                                </div>
                                <p className="hieght-18">Save</p>
                                {/* qgw{isLoading && <div className="loader float-right ease-linear text-center rounded-full -mt-1 ml-3 border-2 border-t-2 border-gray-200 h-5 w-5"></div>} */}
                              </button>
                            ) : (
                              <button
                                onClick={saveEPrescription}
                                className={
                                  "cursor-pointer bg-brand-secondary float-left text-white font-medium py-2 px-3 w-1/5 h-25 rounded text-xs"
                                }
                              >
                                <div className="flex justify-center mb-2">
                                  <img src={endcall} />
                                </div>
                                <p className="hieght-18">End Consultation</p>
                              </button>
                            )}
                          </div>
                          {eyeWearUser && (
                            <div className="border border-gray-200 my-3 rounded-lg p-5 mt-10">
                              <div className="flex">
                                <div className="newtable-list">
                                  <ul className="font-bold text-base">
                                    <li>Rd1</li>
                                    <li>Rd2</li>
                                    <li>Rd3</li>
                                    <li>Final</li>
                                  </ul>
                                </div>
                                <div className="">
                                  <div
                                    className="w-full rounded-lg flex justify-evenly items-center p-3"
                                    style={{ background: "#EFFAFF" }}
                                  >
                                    <h6 className="text-brand-primary font-Rubik text-lg font-semibold">
                                      Right Eye
                                    </h6>
                                    <div
                                      className="border-l h-6"
                                      style={{
                                        borderColor: "rgba(0, 93, 141, 0.34)",
                                      }}
                                    ></div>
                                    <h6 className="text-brand-primary font-Rubik text-lg font-semibold">
                                      Left Eye
                                    </h6>
                                  </div>
                                  <div className="grid grid-cols-2 gap-8 mt-5">
                                    <div className="border border-gray-200  rounded-lg pb-3">
                                      <table className="newtable">
                                        <tr>
                                          <th>Sph</th>
                                          <th>Cyl</th>
                                          <th>Axis</th>
                                          <th>Add Power</th>
                                          <th>VA</th>
                                        </tr>

                                        <tr>
                                          <td>
                                            <input
                                              name="sph"
                                              id="sph"
                                              value={reading1RE.sph}
                                              onChange={onREReading1}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="cyl"
                                              id="cyl"
                                              value={reading1RE.cyl}
                                              onChange={onREReading1}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="axis"
                                              id="axis"
                                              value={reading1RE.axis}
                                              onChange={onREReading1}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="addPower"
                                              id="addPower"
                                              value={reading1RE.addPower}
                                              onChange={onREReading1}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="vA"
                                              id="vA"
                                              value={reading1RE.vA}
                                              onChange={onREReading1}
                                              type="text"
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <input
                                              name="sph"
                                              id="sph"
                                              value={reading2RE.sph}
                                              onChange={onREReading2}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="cyl"
                                              id="cyl"
                                              value={reading2RE.cyl}
                                              onChange={onREReading2}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="axis"
                                              id="axis"
                                              value={reading2RE.axis}
                                              onChange={onREReading2}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="addPower"
                                              id="addPower"
                                              value={reading2RE.addPower}
                                              onChange={onREReading2}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="vA"
                                              id="vA"
                                              value={reading2RE.vA}
                                              onChange={onREReading2}
                                              type="text"
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <input
                                              name="sph"
                                              id="sph"
                                              value={reading3RE.sph}
                                              onChange={onREReading3}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="cyl"
                                              id="cyl"
                                              value={reading3RE.cyl}
                                              onChange={onREReading3}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="axis"
                                              id="axis"
                                              value={reading3RE.axis}
                                              onChange={onREReading3}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="addPower"
                                              id="addPower"
                                              value={reading3RE.addPower}
                                              onChange={onREReading3}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="vA"
                                              id="vA"
                                              value={reading3RE.vA}
                                              onChange={onREReading3}
                                              type="text"
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <input
                                              name="sph"
                                              id="sph"
                                              value={readingFinalRE.sph}
                                              onChange={onREReadingFinal}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="cyl"
                                              id="cyl"
                                              value={readingFinalRE.cyl}
                                              onChange={onREReadingFinal}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="axis"
                                              id="axis"
                                              value={readingFinalRE.axis}
                                              onChange={onREReadingFinal}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="addPower"
                                              id="addPower"
                                              value={readingFinalRE.addPower}
                                              onChange={onREReadingFinal}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="vA"
                                              id="vA"
                                              value={readingFinalRE.vA}
                                              onChange={onREReadingFinal}
                                              type="text"
                                            />
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                    <div className="border border-gray-200  rounded-lg pb-3">
                                      <table className="newtable">
                                        <tr>
                                          <th>Sph</th>
                                          <th>Cyl</th>
                                          <th>Axis</th>
                                          <th>Add Power</th>
                                          <th>VA</th>
                                        </tr>
                                        <tr>
                                          <td>
                                            <input
                                              name="sph"
                                              id="sph"
                                              value={reading1LE.sph}
                                              onChange={onLEReading1}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="cyl"
                                              id="cyl"
                                              value={reading1LE.cyl}
                                              onChange={onLEReading1}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="axis"
                                              id="axis"
                                              value={reading1LE.axis}
                                              onChange={onLEReading1}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="addPower"
                                              id="addPower"
                                              value={reading1LE.addPower}
                                              onChange={onLEReading1}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="vA"
                                              id="vA"
                                              value={reading1LE.vA}
                                              onChange={onLEReading1}
                                              type="text"
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <input
                                              name="sph"
                                              id="sph"
                                              value={reading2LE.sph}
                                              onChange={onLEReading2}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="cyl"
                                              id="cyl"
                                              value={reading2LE.cyl}
                                              onChange={onLEReading2}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="axis"
                                              id="axis"
                                              value={reading2LE.axis}
                                              onChange={onLEReading2}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="addPower"
                                              id="addPower"
                                              value={reading2LE.addPower}
                                              onChange={onLEReading2}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="vA"
                                              id="vA"
                                              value={reading2LE.vA}
                                              onChange={onLEReading2}
                                              type="text"
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <input
                                              name="sph"
                                              id="sph"
                                              value={reading3LE.sph}
                                              onChange={onLEReading3}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="cyl"
                                              id="cyl"
                                              value={reading3LE.cyl}
                                              onChange={onLEReading3}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="axis"
                                              id="axis"
                                              value={reading3LE.axis}
                                              onChange={onLEReading3}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="addPower"
                                              id="addPower"
                                              value={reading3LE.addPower}
                                              onChange={onLEReading3}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="vA"
                                              id="vA"
                                              value={reading3LE.vA}
                                              onChange={onLEReading3}
                                              type="text"
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <input
                                              name="sph"
                                              id="sph"
                                              value={readingFinalLE.sph}
                                              onChange={onLEReadingFinal}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="cyl"
                                              id="cyl"
                                              value={readingFinalLE.cyl}
                                              onChange={onLEReadingFinal}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="axis"
                                              id="axis"
                                              value={readingFinalLE.axis}
                                              onChange={onLEReadingFinal}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="addPower"
                                              id="addPower"
                                              value={readingFinalLE.addPower}
                                              onChange={onLEReadingFinal}
                                              type="text"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              name="vA"
                                              id="vA"
                                              value={readingFinalLE.vA}
                                              onChange={onLEReadingFinal}
                                              type="text"
                                            />
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : null}

                  {!agoraFeatureFlag && showvideo && (
                    <>
                      <div
                        id="videoload"
                        style={{
                          width: "100px",
                          height: "100px",
                          margin: "auto",
                          textAlign: "center",
                          display: "none",
                        }}
                      >
                        Loading...
                      </div>
                      <div id="videoframe">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `<iframe class="myframe"  allow="camera; microphone;" sandbox="allow-scripts allow-same-origin allow-modals allow-top-navigation" style="width: 100%; height:500px" src=${state.callUrl} frameborder="0" allowfullscreen showControls ></iframe>`,
                          }}
                        ></div>
                      </div>
                    </>
                  )}

                  {!agoraFeatureFlag && showaudio && (
                    <>
                      <div
                        id="audioload"
                        style={{
                          width: "100px",
                          height: "100px",
                          margin: "auto",
                          textAlign: "center",
                          display: "none",
                        }}
                      >
                        Loading...
                      </div>
                      <div id="audioframe">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `<iframe class="myframe" allow="microphone;" style="width: 100%; height: 500px" src=${state.callUrl} frameborder="0" allowfullscreen showControls></iframe>`,
                          }}
                        ></div>
                      </div>
                    </>
                  )}

                  <div className="lg:block lg:mt-5 ">
                    <div>
                      <div className="gap-3.5 pr-2 pl-2 flex mb-2">
                        {(state?.consultationsType == "V" ||
                          state?.consultationsType == "Q") &&
                        !editMode ? (
                          <button
                            onClick={() => joinvideoCall()}
                            className="bg-brand-secondary cursor-pointer  float-left text-white font-medium w-1/5 py-2 px-3 h-25 rounded text-xs"
                            disabled={isCalldisabled}
                          >
                            <div className="flex justify-center mb-2">
                              <img
                                src={state?.consultationsType == "Q" ? qc : vc}
                              />
                            </div>
                            <p className="hieght-18">Join Video Call</p>
                          </button>
                        ) : null}
                        {state?.consultationsType == "A" && !editMode ? (
                          <button
                            onClick={() => joinaudioCall()}
                            className="bg-brand-secondary cursor-pointer  float-left text-white font-medium w-1/5 py-2 px-3 h-25 rounded text-xs"
                            disabled={isCalldisabled}
                          >
                            <div className="flex justify-center mb-2">
                              {/* <img src={vc} /> */}
                              <img src={ac} />
                            </div>
                            <p className="hieght-18">Join Audio Call</p>
                          </button>
                        ) : null}
                        <button
                          onClose={addDrugs}
                          onClick={() => setDrugModal(true)}
                          className="bg-brand-secondary cursor-pointer  float-left text-white font-medium py-2 px-2 w-1/5 h-25 rounded text-[4px] sm:text-xs"
                        >
                          <div className="flex justify-center mb-2">
                            <img src={pills} />
                          </div>
                          <p className="hieght-18">Add Medicine</p>
                        </button>
                        <button
                          onClose={addLabTest}
                          onClick={() => setLabModal(true)}
                          className="bg-brand-secondary cursor-pointer float-left text-white font-medium py-2 px-3 w-1/5 h-25 rounded text-[4px] sm:text-xs"
                        >
                          <div className="flex justify-center mb-2">
                            <img src={diagnostics} />
                          </div>
                          <p className="hieght-18">Add Lab Test</p>
                        </button>
                        <button
                          onClick={() => setReferModal(true)}
                          className="bg-brand-secondary cursor-pointer  float-center text-white font-medium py-2 px-3 w-1/5 h-25 rounded text-xs"
                        >
                          <div className="flex justify-center mb-2">
                            <img src={stethoscope} />
                          </div>
                          <p className="hieght-18">Refer To</p>
                        </button>
                        {editMode ? (
                          <button
                            onClick={updateEPrescription}
                            className={
                              "cursor-pointer bg-brand-secondary float-left text-white font-medium py-2 px-3 w-1/5 h-25 rounded text-xs"
                            }
                          >
                            <div className="flex justify-center mb-2">
                              <img src={endcall} />
                            </div>
                            <p className="hieght-18">Save</p>
                            {/* qgw{isLoading && <div className="loader float-right ease-linear text-center rounded-full -mt-1 ml-3 border-2 border-t-2 border-gray-200 h-5 w-5"></div>} */}
                          </button>
                        ) : (
                          <button
                            onClick={saveEPrescription}
                            className={
                              "cursor-pointer bg-brand-secondary float-left text-white font-medium py-2 px-3 w-1/5 h-25 rounded text-xs"
                            }
                          >
                            <div className="flex justify-center mb-2">
                              <img src={endcall} />
                            </div>
                            <p className="hieght-18">End Consultation</p>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {!showvideo && (
                <div className=" lg:hidden flex justify-center mb-2">
                  {(state?.consultationsType == "V" ||
                    state?.consultationsType == "Q") && (
                    <button
                      onClick={() => joinvideoCall()}
                      className="bg-brand-secondary cursor-pointer  float-left text-white font-medium  py-2 px-3 rounded text-xs"
                      disabled={isCalldisabled}
                    >
                      Join Video Call
                    </button>
                  )}
                </div>
              )}

              {!showaudio && (
                <div className=" lg:hidden flex justify-center mb-2">
                  {state?.consultationsType == "A" && (
                    <button
                      onClick={() => joinaudioCall()}
                      className="bg-brand-secondary cursor-pointer  float-left text-white font-medium  py-2 px-3 rounded text-xs"
                      disabled={isCalldisabled}
                    >
                      Join Audio Call
                    </button>
                  )}
                </div>
              )}

              <div className="lg:w-4/12 lg:-mt-2 mt-3  sm:relative relative top-0 right-0">
                <div className="mb-14 sm:m-0 m-1 rounded border p-6 lg:ml-8 h-screen overflow-y-auto">
                  <div className="flex border-b pb-5 items-center">
                    <img src={Consulatationicon} alt="image" height={"23px"} />
                    <p
                      style={{ color: "#374151" }}
                      className="ml-3 text-lg text-gray-primary font-normal font-rubik"
                    >
                      Consultation Notes
                    </p>
                  </div>
                  <br />
                  <div className="mb-5">
                    <label
                      for="email"
                      className="text-base text-gray-primary font-semibold font-rubik"
                    >
                      Findings
                    </label>

                    <div className="flex">
                      <MultiSelect
                        value={selectsymptomsList}
                        options={symptomsList ? symptomsList : []}
                        style={{
                          width: "100% !important",
                          maxWidth: "100% !important",
                        }}
                        panelClassName={{ width: "100%" }}
                        panelStyle={{ width: "100%" }}
                        className="newmulti-select flex w-full peer mt-2 text-gray-500 border-black-500 focus:outline-none focus:borer-rose-600"
                        onChange={(e) => setselsymptomsList(e.value)}
                        // onHide={addLabTests}
                        optionLabel="symptomsName"
                        // selectedItemTemplate={(option) => <span style={{ marginRight: '8px' }}>{option.name}</span>}
                        // menuStyle={{ width: '100%' }}
                        optionValue="symptomsName"
                        // filter placeholder="Select"
                        filter={true}
                        filterPlaceholder="Select"
                      />
                    </div>
                  </div>

                  <div className="w-4/5">
                    {/* {selectsymptomsList.length > 0 &&
                                            <span className="text-base text-gray-primary font-medium font-rubik">Finding <span class="text-red-600"> *</span> :</span>
                                        } */}
                    {selectsymptomsList && selectsymptomsList.length > 0 && (
                      <div className="border-2  h-28 overflow-y-auto">
                        {selectsymptomsList.length > 0 &&
                          selectsymptomsList.map((data, i) => (
                            <div key={i} className="w-full px-2">
                              <div className="flex m-1 justify-between">
                                <div className="text-gray-primary text-gray-400 text-xs font-rubik">
                                  {data}
                                </div>
                                <div className="h-4 w-4 ml-20">
                                  <TrashIcon
                                    onClick={() => onRemove(i)}
                                    stroke={"#004171"}
                                    className="cursor-pointer h-4 w-4"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                    {/* </div>
              </div>
            </div> */}
                  </div>

                  {/* <div>
  {typeof selectsymptomsList === 'string' && (
    <p>{selectsymptomsList.split(',').join(' ')}</p>
  )}
  
</div> */}

                  <div className="mt-5" style={{ display: "contents" }}>
                    <p className="text-base text-gray-primary font-semibold font-rubik mb-3">
                      Diagnosis
                    </p>
                    <div className="relative w-full sm:w-auto mb-5">
                      <label
                        for="email"
                        className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Primary
                      </label>
                      <div className="flex w-full items-center">
                        <div className="autocomplete-wrapper newauto-search">
                          <Autocomplete
                            name={inputRef}
                            value={
                              visitData.primaryDiagnosis
                                ? visitData.primaryDiagnosis
                                : val1
                            }
                            items={list}
                            getItemValue={(item) => item.termCode}
                            shouldItemRender={renderDescription}
                            menuStyle={{ width: "100%" }}
                            renderMenu={(item) => (
                              <div
                                className="dropdown"
                                style={{
                                  width: "100%",
                                  zIndex: "9999",
                                  backgroundColor: "#fff",
                                  position: "relative",
                                }}
                              >
                                {item}
                              </div>
                            )}
                            autoFocus
                            renderItem={(item, isHighlighted) => (
                              <div
                                className={`item ${
                                  isHighlighted ? "selected-item" : ""
                                }`}
                              >
                                {item.description}
                              </div>
                            )}
                            onChange={(event, val) => {
                              onSetVal(val, 1);
                              setVisitData({
                                ...visitData,
                                ["primaryDiagnosis"]: "",
                              });
                            }}
                            onSelect={(event, obj) => onChangeHandlerD(obj)}
                            ref={inputPrimaryDiagnosisRef}
                          />
                        </div>
                        <SearchIcon
                          onClick={(e) => {
                            focusPrimaryDiagnosis(e);
                          }}
                          className="w-4 h-4 right-4 absolute "
                        />
                      </div>
                    </div>
                    <div className="relative w-full sm:w-auto mb-5 ">
                      <label
                        for="email"
                        className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        ICD-10 code
                      </label>
                      <div className="flex w-full items-center">
                        <div className="autocomplete-wrapper newauto-search">
                          <Autocomplete
                            value={
                              visitData.primarySymptoms
                                ? visitData.primarySymptoms
                                : val3
                            }
                            items={list}
                            getItemValue={(item) => item.description}
                            shouldItemRender={renderTermcode}
                            menuStyle={{ width: "100%" }}
                            renderMenu={(item) => (
                              <div
                                className="dropdown"
                                style={{
                                  width: "100%",
                                  zIndex: "9999",
                                  backgroundColor: "#fff",
                                  position: "relative",
                                }}
                              >
                                {item}
                              </div>
                            )}
                            renderItem={(item, isHighlighted) => (
                              <div
                                className={`item ${
                                  isHighlighted ? "selected-item" : ""
                                }`}
                              >
                                {item.termCode}
                              </div>
                            )}
                            onChange={(event, val) => {
                              onSetVal(val, 3);
                              setVisitData({
                                ...visitData,
                                ["primarySymptoms"]: "",
                              });
                            }}
                            onSelect={(event, obj) => onChangeHandlerD3(obj)}
                            ref={inputPrimaryDiagnosisCodeRef}
                          />
                        </div>

                        {/* <input value={visitData.primarySymptoms} readonly onChange={onChangeHandler} autocomplete="off" id="primarySymptoms" name="primarySymptoms" type="text" className=" text-xs peer w-full sm:w-36   h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 " placeholder="" /> */}
                        <SearchIcon
                          onClick={(e) => {
                            focusPrimaryDiagnosisCode(e);
                          }}
                          className="w-4 h-4 right-4 absolute"
                        />
                      </div>
                    </div>
                    <div className="relative w-full sm:w-auto mb-5">
                      <label
                        for="email"
                        className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Secondary
                      </label>
                      <div className="flex items-center w-full">
                        <div className="autocomplete-wrapper newauto-search">
                          <Autocomplete
                            value={
                              visitData.secondaryDiagnosis
                                ? visitData.secondaryDiagnosis
                                : val2
                            }
                            items={list}
                            getItemValue={(item) => item.termCode}
                            shouldItemRender={renderDescription}
                            menuStyle={{ width: "100%" }}
                            renderMenu={(item) => (
                              <div
                                className="dropdown"
                                style={{
                                  width: "100%",
                                  zIndex: "9999",
                                  backgroundColor: "#fff",
                                  position: "relative",
                                }}
                              >
                                {item}
                              </div>
                            )}
                            renderItem={(item, isHighlighted) => (
                              <div
                                className={`item ${
                                  isHighlighted ? "selected-item" : ""
                                }`}
                              >
                                {item.description}
                              </div>
                            )}
                            onChange={(event, val) => {
                              onSetVal(val, 2);
                              setVisitData({
                                ...visitData,
                                ["secondaryDiagnosis"]: "",
                              });
                            }}
                            onSelect={(event, obj) => onChangeHandlerD2(obj)}
                            ref={inputSecondryDiagnosisRef}
                          />
                        </div>
                        <SearchIcon
                          onClick={(e) => {
                            focusSecondaryDiagnosis(e);
                          }}
                          className="w-4 h-4 right-4 absolute"
                        />
                      </div>
                    </div>
                    <div className="relative w-full sm:w-auto ">
                      <label
                        for="email"
                        className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        ICD-10 code
                      </label>
                      <div className="flex items-center w-full">
                        <div className="autocomplete-wrapper newauto-search">
                          <Autocomplete
                            value={
                              visitData.secondarySymptoms
                                ? visitData.secondarySymptoms
                                : val4
                            }
                            items={list}
                            getItemValue={(item) => item.description}
                            shouldItemRender={renderTermcode}
                            menuStyle={{ width: "100%" }}
                            renderMenu={(item) => (
                              <div
                                className="dropdown"
                                style={{
                                  width: "100%",
                                  zIndex: "9999",
                                  backgroundColor: "#fff",
                                  position: "relative",
                                }}
                              >
                                {item}
                              </div>
                            )}
                            renderItem={(item, isHighlighted) => (
                              <div
                                className={`item ${
                                  isHighlighted ? "selected-item" : ""
                                }`}
                              >
                                {item.termCode}
                              </div>
                            )}
                            onChange={(event, val) => {
                              onSetVal(val, 4);
                              setVisitData({
                                ...visitData,
                                ["secondarySymptoms"]: "",
                              });
                            }}
                            onSelect={(event, obj) => onChangeHandlerD4(obj)}
                            ref={inputSecondryDiagnosisCodeRef}
                          />
                        </div>
                        {/* <input value={visitData.secondarySymptoms} readonly onChange={onChangeHandler} autocomplete="off" id="secondarySymptoms" name="secondarySymptoms" type="text" className=" text-xs peer w-full w-36  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 " placeholder="" /> */}
                        <SearchIcon
                          onClick={(e) => {
                            focusSecondaryDiagnosisCode(e);
                          }}
                          className="w-4 h-4 right-4 absolute"
                        />
                      </div>
                    </div>

                    <div className="mt-5">
                      <p className="text-base mt-1 text-gray-primary font-semibold font-rubik">
                        Symptoms
                      </p>
                    </div>
                    <div className="relative mt-3 mb-6">
                      <div className="flex ">
                        <textarea
                          rows={3}
                          autocomplete="off"
                          id="symptoms"
                          name="symptoms"
                          value={visitData.symptoms}
                          onChange={onChangeHandler}
                          type="text"
                          className=" text-xs peer w-full  h-16 newtextarea border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                          placeholder=""
                        />
                      </div>
                    </div>
                    {eyeWearUser == false && (
                      <div className="">
                        <p className="text-base text-gray-primary font-medium font-rubik">
                          History of Presenting Illness:
                        </p>
                        <br />
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Site
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="site"
                              name="site"
                              value={visitData.site}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Onset{" "}
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="onset"
                              name="onset"
                              value={visitData.onset}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Character
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="characteristics"
                              name="characteristics"
                              value={visitData.characteristics}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Radiation
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="radiation"
                              name="radiation"
                              value={visitData.radiation}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Associated Symptoms
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="associatedSymptoms"
                              name="associatedSymptoms"
                              value={visitData.associatedSymptoms}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>

                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Timing
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="timing"
                              name="timing"
                              value={visitData.timing}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>

                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Exacerbating Factors
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="exacerbatingFactors"
                              name="exacerbatingFactors"
                              value={visitData.exacerbatingFactors}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Relieving Factors
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="relievingFactors"
                              name="relievingFactors"
                              value={visitData.relievingFactors}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Severity
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <select
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              id="severity"
                              name="severity"
                              value={visitData.severity}
                              onChange={onChangeHandler}
                            >
                              <option class="" value="">
                                Select Severity
                              </option>
                              <option class="py-1" value="1">
                                1
                              </option>
                              <option class="py-1" value="2">
                                2
                              </option>
                              <option class="py-1" value="3">
                                3
                              </option>
                              <option class="py-1" value="4">
                                4
                              </option>
                              <option class="py-1" value="5">
                                5
                              </option>
                              <option class="py-1" value="6">
                                6
                              </option>
                              <option class="py-1" value="7">
                                7
                              </option>
                              <option class="py-1" value="8">
                                8
                              </option>
                              <option class="py-1" value="9">
                                9
                              </option>
                              <option class="py-1" value="10">
                                10
                              </option>
                            </select>
                            {/* <textarea rows={3} autocomplete="off" id="severity" name="severity" value={visitData.severity} onChange={onChangeHandler} type="text" className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 " placeholder="" /> */}
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Any Treatment Taken?
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="treatmentTaken"
                              name="treatmentTaken"
                              value={visitData.treatmentTaken}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>

                        {/* <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Comments
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="comments"
                              name="comments"
                              value={visitData.comments}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div> */}
                      </div>
                    )}
                    <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                      Comments
                    </p>
                    <div className="relative mt-3 mb-6">
                      <div className="flex ">
                        <textarea
                          rows={3}
                          autocomplete="off"
                          id="comments"
                          name="comments"
                          value={visitData.comments}
                          onChange={onChangeHandler}
                          type="text"
                          className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                          placeholder=""
                        />
                      </div>
                      {/* <label for="email" className="absolute left-0 -top-3.5 text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Notes</label> */}
                    </div>
                    {eyeWearUser == true && (
                      <>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Frame
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="frame"
                              name="frame"
                              value={visitData.frame}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Coating
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="coating"
                              name="coating"
                              value={visitData.coating}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          NV Assessment
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="nvAssessment"
                              name="nvAssessment"
                              value={visitData.nvAssessment}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          DV Assessment
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="dvAssessment"
                              name="dvAssessment"
                              value={visitData.dvAssessment}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Treatment Option
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="treatmentOption"
                              name="treatmentOption"
                              value={visitData.treatmentOption}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Any Surgery Recommendation?
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <select
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              id="surgeryValue"
                              name="surgeryValue"
                              value={surgeryValue}
                              onChange={onChangeHandlerSurgeryEye}
                            >
                              <option class="" value="">
                                Select
                              </option>
                              <option class="py-1" value="Yes">
                                Yes
                              </option>
                              <option class="py-1" value="No">
                                No
                              </option>
                            </select>
                          </div>
                        </div>
                        {surgeryEye && (
                          <>
                            <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                              Type of Surgery
                            </p>
                            <div className="relative mt-3 mb-6">
                              <div className="flex ">
                                <textarea
                                  rows={3}
                                  autocomplete="off"
                                  id="surgeryRecommendation"
                                  name="surgeryRecommendation"
                                  value={visitData.surgeryRecommendation}
                                  onChange={onChangeHandler}
                                  type="text"
                                  className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                                  placeholder=""
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    <div>
                      <p className="text-base mt-1 text-gray-primary font-semibold font-rubik">
                        Recommendation
                      </p>
                      <div className="relative mt-3 mb-6">
                        <div className="flex ">
                          <textarea
                            rows={3}
                            autocomplete="off"
                            id="recommendation"
                            name="recommendation"
                            value={visitData.recommendation}
                            onChange={onChangeHandler}
                            type="text"
                            className=" text-xs peer w-full  h-16 newtextarea border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                            placeholder=""
                          />
                        </div>
                        {/* <label for="email" className="absolute left-0 -top-3.5 text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Notes</label> */}
                      </div>
                    </div>
                    <div className="relative mt-3 mb-6">
                      <label
                        for="email"
                        className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                      >
                        Schedule Follow up
                      </label>
                      <div className="flex mt-2">
                        <DatePicker
                          name="followUpVisitDate"
                          id="followUpVisitDate"
                          minDate={moment().format("DD/MM/yyyy")}
                          dateFormat={"DD/MM/yyyy"}
                          value={moment(dates).format("DD/MM/yyyy")}
                          onChange={(e) => {
                            setVisitData({
                              ...visitData,
                              ["followUpVisitDate"]: moment(e).format(
                                "yyyy-MM-DD hh:mm:ss"
                              ),
                            });
                            setDate(moment(e).format("MM/DD/yyyy"));
                          }}
                          placeholderText="Follow up date"
                          className="bg-transparent border-b text-sm newdate"
                          autoComplete="off"
                        />
                      </div>
                    </div>

                    <div className=" mt-3 mb-6">
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M2.25 15.3V2.7C2.25 2.58065 2.29741 2.46619 2.3818 2.3818C2.46619 2.29741 2.58065 2.25 2.7 2.25H15.3C15.4193 2.25 15.5338 2.29741 15.6182 2.3818C15.7026 2.46619 15.75 2.58065 15.75 2.7V15.3C15.75 15.4193 15.7026 15.5338 15.6182 15.6182C15.5338 15.7026 15.4193 15.75 15.3 15.75H2.7C2.58065 15.75 2.46619 15.7026 2.3818 15.6182C2.29741 15.5338 2.25 15.4193 2.25 15.3V15.3Z"
                            stroke="#262626"
                          />
                          <path
                            d="M4.5 13.5H13.5M9 10.5V4.5M9 4.5L11.625 7.125M9 4.5L6.375 7.125"
                            stroke="#262626"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <button
                          onClick={clicktype}
                          className="cursor-pointer underline text-sm text-gray font-medium py-2 px-3 h-25 rounded text-xs"
                          disabled={
                            visitData.primaryDiagnosis ||
                            visitData.primarySymptoms ||
                            visitData.secondaryDiagnosis ||
                            visitData.secondarySymptoms ||
                            visitData.symptoms ||
                            visitData.recommendation
                              ? true
                              : false
                          }
                        >
                          <p className="hieght-18">
                            {"Scan & Upload Prescription"}
                          </p>
                        </button>
                      </div>

                      {prescriptionDoc ? (
                        <a
                          href={preview}
                          target="_blank"
                          className="font-medium flex"
                        >
                          View Uploaded Prescription &nbsp;
                          {/* <EyeIcon className="h-5  cursor-pointer mt-auto" /> */}
                          <img
                            src={prescription1}
                            className="h-7 cursor-pointer mt-auto"
                            alt="prescription"
                          />
                        </a>
                      ) : null}
                      <input
                        ref={hiddenFileInput}
                        id="filechoose"
                        type="file"
                        accept="application/image;application/pdf"
                        onChange={(e) => {
                          changeHandler(e.target.files[0]);
                        }}
                        className="hidden"
                      />
                    </div>

                    <button
                      onClick={() => previewClick()}
                      className="bg-brand-secondary cursor-pointer mb-5  md:float-left text-white font-medium w-full  py-3 px-3 rounded text-base"
                    >
                      Preview
                    </button>
                    <div className="lg:hidden md:flex-row  gap-3 flex flex-col md:gap-2">
                      <div className="sm:gap-2 sm:flex flex flex-row gap-3 justify-center sm:justify-between sm:mb-2 ">
                        <button
                          onClose={addDrugs}
                          onClick={() => setDrugModal(true)}
                          className="bg-brand-secondary cursor-pointer  float-left text-white font-medium py-2 px-9 md:px-8 rounded text-xs w-36"
                        >
                          Add Medicine
                        </button>
                        <button
                          onClose={addLabTest}
                          onClick={() => setLabModal(true)}
                          className="bg-brand-secondary cursor-pointer float-left text-white font-medium py-2 px-9 md:px-8 rounded text-xs w-36"
                        >
                          Add Lab Test
                        </button>
                      </div>
                      <div className="sm:gap-2 sm:flex flex flex-row gap-3 justify-center  sm:justify-between sm:mb-4 md:mb-2">
                        <button
                          onClick={() => setReferModal(true)}
                          className="bg-brand-secondary cursor-pointer  float-left text-white font-medium py-2 px-12 rounded text-xs w-36"
                        >
                          Refer To
                        </button>
                        <button
                          onClick={saveEPrescription}
                          className={
                            "cursor-pointer text-center bg-brand-secondary  flex float-left text-white font-medium py-2 px-6 md:px-5 rounded text-xs w-36"
                          }
                        >
                          End Consultation
                          {/* qgw{isLoading && <div className="loader float-right ease-linear text-center rounded-full -mt-1 ml-3 border-2 border-t-2 border-gray-200 h-5 w-5"></div>} */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="lg:w-8/12 lg:-mt-2 bg-gray-50 mt-3 ">
                <div className="h-auto md:h-full m-1 rounded border p-2 pb-12 overflow-y-scroll">
                  <p className="text-base text-gray-primary font-medium font-rubik">
                    Consultation Notes
                  </p>
                  <br />
                  <div>
                    <label
                      for="email"
                      className="text-base text-gray-primary font-medium font-rubik"
                    >
                      Findings
                    </label>
                    <div className="flex">
                      <MultiSelect
                        value={selectsymptomsList}
                        options={symptomsList ? symptomsList : []}
                        style={{
                          width: "100% !important",
                          maxWidth: "350px !important",
                        }}
                        panelClassName={{ width: "100%" }}
                        panelStyle={{ width: "100%" }}
                        className="flex w-full peer maxwidth-panel border-b-2 text-gray-500 border-black-500 focus:outline-none focus:borer-rose-600"
                        onChange={(e) => setselsymptomsList(e.value)}
                        // onHide={addLabTests}
                        optionLabel="symptomsName"
                        menuStyle={{ width: "100%" }}
                        optionValue="symptomsName"
                        filter
                        placeholder="Select"
                      />
                    </div>
                    <div className="w-5/12">
                      {/* {selectsymptomsList.length > 0 &&
                                            <p className="text-base text-gray-primary font-medium font-rubik">Findings<span class="text-red-600"> *</span> :</p>

                                        } */}
                      {selectsymptomsList && selectsymptomsList.length > 0 && (
                        <div className="border-2  h-28 overflow-y-auto">
                          {selectsymptomsList.length > 0 &&
                            selectsymptomsList.map((data, i) => (
                              <div key={i} className="w-full px-2">
                                <div className="flex m-1 justify-between">
                                  <div className="text-gray-primary text-gray-400 text-xs font-rubik">
                                    {data}
                                  </div>
                                  <div className="h-4 w-4 ml-20">
                                    <TrashIcon
                                      onClick={() => onRemove(i)}
                                      stroke={"#004171"}
                                      className="cursor-pointer h-4 w-4"
                                      aria-hidden="true"
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                      {/* </div>
              </div>
            </div> */}
                    </div>
                  </div>
                  <div className="mt-2">
                    <p className="text-base text-gray-primary font-medium font-rubik">
                      Diagnosis
                    </p>
                    <div className="flex justify-between flex-wrap mb-6 mt-6">
                      <div className="relative w-full sm:w-auto">
                        <label
                          for="email"
                          className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                        >
                          Primary
                        </label>
                        <div className="flex border-b-2 w-full">
                          <div className="autocomplete-wrapper">
                            <Autocomplete
                              className="w-32"
                              value={
                                visitData.primaryDiagnosis
                                  ? visitData.primaryDiagnosis
                                  : val1
                              }
                              items={list}
                              getItemValue={(item) => item.termCode}
                              shouldItemRender={renderDescription}
                              menuStyle={{ width: "400" }}
                              renderMenu={(item) => (
                                <div
                                  className="dropdown"
                                  style={{ width: "100%" }}
                                >
                                  {item}
                                </div>
                              )}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  className={`item ${
                                    isHighlighted ? "selected-item" : ""
                                  }`}
                                >
                                  {item.description}
                                </div>
                              )}
                              onChange={(event, val) => {
                                onSetVal(val, 1);
                                setVisitData({
                                  ...visitData,
                                  ["primaryDiagnosis"]: "",
                                });
                              }}
                              onSelect={(event, obj) => onChangeHandlerD(obj)}
                            />
                          </div>
                          <SearchIcon className="w-4 h-4 right-0 absolute" />
                        </div>
                      </div>
                      <div className="relative w-full sm:w-auto">
                        <label
                          for="email"
                          className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                        >
                          ICD code
                        </label>
                        <div className="flex border-b-2 w-full">
                          <div className="autocomplete-wrapper">
                            <Autocomplete
                              className="w-10"
                              value={
                                visitData.primarySymptoms
                                  ? visitData.primarySymptoms
                                  : val3
                              }
                              items={list}
                              getItemValue={(item) => item.description}
                              shouldItemRender={renderTermcode}
                              menuStyle={{ width: "10" }}
                              renderMenu={(item) => (
                                <div className="dropdown">{item}</div>
                              )}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  className={`item ${
                                    isHighlighted ? "selected-item" : ""
                                  }`}
                                >
                                  {item.termCode}
                                </div>
                              )}
                              onChange={(event, val) => {
                                onSetVal(val, 3);
                                setVisitData({
                                  ...visitData,
                                  ["primarySymptoms"]: "",
                                });
                              }}
                              onSelect={(event, obj) => onChangeHandlerD3(obj)}
                            />
                          </div>
                          {/* <input value={visitData.primarySymptoms} readonly onChange={onChangeHandler} autocomplete="off" id="primarySymptoms" name="primarySymptoms" type="text" className=" text-xs peer w-80  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 " placeholder="" /> */}
                          <SearchIcon className="w-4 h-4 right-0 absolute" />
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between flex-wrap mb-6 mt-6">
                      <div className="relative w-full sm:w-auto">
                        <label
                          for="email"
                          className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                        >
                          Secondary
                        </label>

                        <div className="flex border-b-2  w-full">
                          <div className="autocomplete-wrapper">
                            <Autocomplete
                              className="w-32"
                              value={
                                visitData.secondaryDiagnosis
                                  ? visitData.secondaryDiagnosis
                                  : val2
                              }
                              items={list}
                              getItemValue={(item) => item.termCode}
                              shouldItemRender={renderDescription}
                              menuStyle={{ width: "400" }}
                              renderMenu={(item) => (
                                <div
                                  className="dropdown"
                                  style={{ width: "100%" }}
                                >
                                  {item}
                                </div>
                              )}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  className={`item ${
                                    isHighlighted ? "selected-item" : ""
                                  }`}
                                >
                                  {item.description}
                                </div>
                              )}
                              onChange={(event, val) => {
                                onSetVal(val, 2);
                                setVisitData({
                                  ...visitData,
                                  ["secondaryDiagnosis"]: "",
                                });
                              }}
                              onSelect={(event, obj) => onChangeHandlerD2(obj)}
                            />
                          </div>
                          <SearchIcon className="w-4 h-4 right-0 absolute" />
                        </div>
                      </div>
                      <div className="relative w-full sm:w-auto">
                        <label
                          for="email"
                          className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                        >
                          ICD code
                        </label>
                        <div className="flex border-b-2 w-full">
                          <div className="autocomplete-wrapper">
                            <Autocomplete
                              className="w-5"
                              value={
                                visitData.secondarySymptoms
                                  ? visitData.secondarySymptoms
                                  : val4
                              }
                              items={list}
                              getItemValue={(item) => item.description}
                              shouldItemRender={renderTermcode}
                              menuStyle={{ width: "30" }}
                              renderMenu={(item) => (
                                <div
                                  className="dropdown"
                                  style={{ width: "100%" }}
                                >
                                  {item}
                                </div>
                              )}
                              renderItem={(item, isHighlighted) => (
                                <div
                                  className={`item ${
                                    isHighlighted ? "selected-item" : ""
                                  }`}
                                >
                                  {item.termCode}
                                </div>
                              )}
                              onChange={(event, val) => {
                                onSetVal(val, 4);
                                setVisitData({
                                  ...visitData,
                                  ["secondarySymptoms"]: "",
                                });
                              }}
                              onSelect={(event, obj) => onChangeHandlerD4(obj)}
                            />
                          </div>
                          {/* <input value={visitData.secondarySymptoms} readonly onChange={onChangeHandler} autocomplete="off" id="secondarySymptoms" name="secondarySymptoms" type="text" className=" text-xs peer w-80  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 " placeholder="" /> */}
                          <SearchIcon className="w-4 h-4 right-0 absolute" />
                        </div>
                      </div>
                    </div>
                    {/* <div className="flex justify-between">
                                 <div className="relative">
                                     <div className="flex ">
                                         <div className="autocomplete-wrapper">
                                             <Autocomplete
                                                 className="w-32"
                                                 value={visitData.secondaryDiagnosis ? visitData.secondaryDiagnosis : val2}
                                                 items={list}
                                                 getItemValue={item => item.termCode}
                                                 shouldItemRender={renderDescription}
                                                 menuStyle={{ width: '400' }}
                                                 renderMenu={item => (
                                                     <div className="dropdown">
                                                         {item}
                                                     </div>
                                                 )}
                                                 renderItem={(item, isHighlighted) =>
                                                     <div className={`item ${isHighlighted ? 'selected-item' : ''}`}>
                                                         {item.description}
                                                     </div>
                                                 }

                                                 onChange={(event, val) => { onSetVal(val, 2); setVisitData({ ...visitData, ['secondaryDiagnosis']: '' }) }}
                                                 onSelect={(event, obj) => onChangeHandlerD2(obj)}
                                             />
                                         </div>
                                     </div>
                                     <label for="email" className="absolute left-0 -top-3.5 text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Secondary</label>
                                 </div>
                                 <div className="relative">
                                     <div className="flex ">
                                         <input readonly autocomplete="off" id="secondarySymptoms" value={visitData.secondarySymptoms} onChange={onChangeHandler} name="secondarySymptoms" type="text" className=" text-xs peer w-36  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 " placeholder="" />
                                     </div>
                                     <label for="email" className="absolute left-0 -top-3.5 text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">ICD Code</label>
                                 </div>
                             </div> */}
                    <br />
                    <p className="text-base mt-1 text-gray-primary font-medium font-rubik">
                      Symptoms
                    </p>
                    <div className="relative mt-3 mb-6">
                      <div className="flex ">
                        <textarea
                          rows={3}
                          autocomplete="off"
                          id="symptoms"
                          name="symptoms"
                          value={visitData.symptoms}
                          onChange={onChangeHandler}
                          type="text"
                          className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                          placeholder=""
                        />
                      </div>
                    </div>
                    {eyeWearUser == false && (
                      <>
                        <p className="text-base text-gray-primary font-medium font-rubik">
                          History of Presenting Illness:
                        </p>
                        <br />
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Site
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="site"
                              name="site"
                              value={visitData.site}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Onset{" "}
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="onset"
                              name="onset"
                              value={visitData.onset}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Character
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="characteristics"
                              name="characteristics"
                              value={visitData.characteristics}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Radiation
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="radiation"
                              name="radiation"
                              value={visitData.radiation}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Associated Symptoms
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="associatedSymptoms"
                              name="associatedSymptoms"
                              value={visitData.associatedSymptoms}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>

                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Timing
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="timing"
                              name="timing"
                              value={visitData.timing}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>

                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Exacerbating Factors
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="exacerbatingFactors"
                              name="exacerbatingFactors"
                              value={visitData.exacerbatingFactors}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Relieving Factors
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="relievingFactors"
                              name="relievingFactors"
                              value={visitData.relievingFactors}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Severity
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <select
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              id="severity"
                              name="severity"
                              value={visitData.severity}
                              onChange={onChangeHandler}
                            >
                              <option class="" value="">
                                Select Severity
                              </option>
                              <option class="py-1" value="1">
                                1
                              </option>
                              <option class="py-1" value="2">
                                2
                              </option>
                              <option class="py-1" value="3">
                                3
                              </option>
                              <option class="py-1" value="4">
                                4
                              </option>
                              <option class="py-1" value="5">
                                5
                              </option>
                              <option class="py-1" value="6">
                                6
                              </option>
                              <option class="py-1" value="7">
                                7
                              </option>
                              <option class="py-1" value="8">
                                8
                              </option>
                              <option class="py-1" value="9">
                                9
                              </option>
                              <option class="py-1" value="10">
                                10
                              </option>
                            </select>
                            {/* <textarea rows={3} autocomplete="off" id="severity" name="severity" value={visitData.severity} onChange={onChangeHandler} type="text" className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 " placeholder="" /> */}
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Any Treatment Taken
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="treatmentTaken"
                              name="treatmentTaken"
                              value={visitData.treatmentTaken}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {eyeWearUser == true && (
                      <>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Frame
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="frame"
                              name="frame"
                              value={visitData.frame}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Coating
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="coating"
                              name="coating"
                              value={visitData.coating}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          NV Assessment
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="nvAssessment"
                              name="nvAssessment"
                              value={visitData.nvAssessment}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          DV Assessment
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="dvAssessment"
                              name="dvAssessment"
                              value={visitData.dvAssessment}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Treatment Option
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <textarea
                              rows={3}
                              autocomplete="off"
                              id="treatmentOption"
                              name="treatmentOption"
                              value={visitData.treatmentOption}
                              onChange={onChangeHandler}
                              type="text"
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              placeholder=""
                            />
                          </div>
                        </div>
                        <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                          Any Surgery Recommendation?
                        </p>
                        <div className="relative mt-3 mb-6">
                          <div className="flex ">
                            <select
                              className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                              id="surgeryValue"
                              name="surgeryValue"
                              value={surgeryValue}
                              onChange={onChangeHandlerSurgeryEye}
                            >
                              <option class="" value="">
                                Select
                              </option>
                              <option class="py-1" value="Yes">
                                Yes
                              </option>
                              <option class="py-1" value="No">
                                No
                              </option>
                            </select>
                          </div>
                        </div>
                        {surgeryEye && (
                          <>
                            <p className="relative left-0  text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">
                              Type of Surgery
                            </p>
                            <div className="relative mt-3 mb-6">
                              <div className="flex ">
                                <textarea
                                  rows={3}
                                  autocomplete="off"
                                  id="surgeryRecommendation"
                                  name="surgeryRecommendation"
                                  value={visitData.surgeryRecommendation}
                                  onChange={onChangeHandler}
                                  type="text"
                                  className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                                  placeholder=""
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    <p className="text-base mt-1 text-gray-primary font-medium font-rubik">
                      Comments
                    </p>
                    <div className="relative mt-3 mb-6">
                      <div className="flex ">
                        <textarea
                          rows={3}
                          autocomplete="off"
                          id="comments"
                          name="comments"
                          value={visitData.comments}
                          onChange={onChangeHandler}
                          type="text"
                          className=" text-xs peer w-full  h-8  border-b-2 border-gray-300 bg-transparent text-gray-900 focus:outline-none focus:borer-rose-600 "
                          placeholder=""
                        />
                      </div>
                      {/* <label for="email" className="absolute left-0 -top-3.5 text-gray-primary font-medium text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm">Notes</label> */}
                    </div>
                    <p className="text-base mt-1 text-gray-primary font-medium font-rubik">
                      Schedule Follow up
                    </p>
                    <div className="relative mt-3 mb-6">
                      <div className="flex ">
                        <DatePicker
                          name="followUpVisitDate"
                          id="followUpVisitDate"
                          minDate={moment().format("DD/MM/yyyy")}
                          dateFormat={"DD/MM/yyyy"}
                          value={moment(dates).format("DD/MM/yyyy")}
                          onChange={(e) => {
                            setVisitData({
                              ...visitData,
                              ["followUpVisitDate"]: moment(e).format(
                                "yyyy-MM-DD hh:mm:ss"
                              ),
                            });
                            setDate(moment(e).format("MM/DD/yyyy"));
                          }}
                          placeholderText="Follow up date"
                          className="bg-transparent border-b text-sm "
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="relative mt-3 mb-6">
                      <div className="flex ">
                        <button
                          onClick={() => previewClick()}
                          className="bg-brand-secondary cursor-pointer  float-left text-white font-medium  py-2 px-3 rounded text-xs"
                        >
                          Preview
                        </button>
                      </div>
                    </div>
                    {eyeWearUser && (
                      <div className="border border-gray-200 my-3 rounded-lg p-5 mt-10">
                        <div className="flex">
                          <div className="newtable-list">
                            <ul className="font-bold text-base">
                              <li>Rd1</li>
                              <li>Rd2</li>
                              <li>Rd3</li>
                              <li>Final</li>
                            </ul>
                          </div>
                          <div className="">
                            <div
                              className="w-full rounded-lg flex justify-evenly items-center p-3"
                              style={{ background: "#EFFAFF" }}
                            >
                              <h6 className="text-brand-primary font-Rubik text-lg font-semibold">
                                Right Eye
                              </h6>
                              <div
                                className="border-l h-6"
                                style={{
                                  borderColor: "rgba(0, 93, 141, 0.34)",
                                }}
                              ></div>
                              <h6 className="text-brand-primary font-Rubik text-lg font-semibold">
                                Left Eye
                              </h6>
                            </div>
                            <div className="grid grid-cols-2 gap-8 mt-5">
                              <div className="border border-gray-200  rounded-lg pb-3">
                                <table className="newtable">
                                  <tr>
                                    <th>Sph</th>
                                    <th>Cyl</th>
                                    <th>Axis</th>
                                    <th>Add Power</th>
                                    <th>VA</th>
                                  </tr>

                                  <tr>
                                    <td>
                                      <input
                                        name="sph"
                                        id="sph"
                                        value={reading1RE.sph}
                                        onChange={onREReading1}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="cyl"
                                        id="cyl"
                                        value={reading1RE.cyl}
                                        onChange={onREReading1}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="axis"
                                        id="axis"
                                        value={reading1RE.axis}
                                        onChange={onREReading1}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="addPower"
                                        id="addPower"
                                        value={reading1RE.addPower}
                                        onChange={onREReading1}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="vA"
                                        id="vA"
                                        value={reading1RE.vA}
                                        onChange={onREReading1}
                                        type="text"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <input
                                        name="sph"
                                        id="sph"
                                        value={reading2RE.sph}
                                        onChange={onREReading2}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="cyl"
                                        id="cyl"
                                        value={reading2RE.cyl}
                                        onChange={onREReading2}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="axis"
                                        id="axis"
                                        value={reading2RE.axis}
                                        onChange={onREReading2}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="addPower"
                                        id="addPower"
                                        value={reading2RE.addPower}
                                        onChange={onREReading2}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="vA"
                                        id="vA"
                                        value={reading2RE.vA}
                                        onChange={onREReading2}
                                        type="text"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <input
                                        name="sph"
                                        id="sph"
                                        value={reading3RE.sph}
                                        onChange={onREReading3}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="cyl"
                                        id="cyl"
                                        value={reading3RE.cyl}
                                        onChange={onREReading3}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="axis"
                                        id="axis"
                                        value={reading3RE.axis}
                                        onChange={onREReading3}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="addPower"
                                        id="addPower"
                                        value={reading3RE.addPower}
                                        onChange={onREReading3}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="vA"
                                        id="vA"
                                        value={reading3RE.vA}
                                        onChange={onREReading3}
                                        type="text"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <input
                                        name="sph"
                                        id="sph"
                                        value={readingFinalRE.sph}
                                        onChange={onREReadingFinal}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="cyl"
                                        id="cyl"
                                        value={readingFinalRE.cyl}
                                        onChange={onREReadingFinal}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="axis"
                                        id="axis"
                                        value={readingFinalRE.axis}
                                        onChange={onREReadingFinal}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="addPower"
                                        id="addPower"
                                        value={readingFinalRE.addPower}
                                        onChange={onREReadingFinal}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="vA"
                                        id="vA"
                                        value={readingFinalRE.vA}
                                        onChange={onREReadingFinal}
                                        type="text"
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </div>
                              <div className="border border-gray-200  rounded-lg pb-3">
                                <table className="newtable">
                                  <tr>
                                    <th>Sph</th>
                                    <th>Cyl</th>
                                    <th>Axis</th>
                                    <th>Add Power</th>
                                    <th>VA</th>
                                  </tr>
                                  <tr>
                                    <td>
                                      <input
                                        name="sph"
                                        id="sph"
                                        value={reading1LE.sph}
                                        onChange={onLEReading1}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="cyl"
                                        id="cyl"
                                        value={reading1LE.cyl}
                                        onChange={onLEReading1}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="axis"
                                        id="axis"
                                        value={reading1LE.axis}
                                        onChange={onLEReading1}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="addPower"
                                        id="addPower"
                                        value={reading1LE.addPower}
                                        onChange={onLEReading1}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="vA"
                                        id="vA"
                                        value={reading1LE.vA}
                                        onChange={onLEReading1}
                                        type="text"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <input
                                        name="sph"
                                        id="sph"
                                        value={reading2LE.sph}
                                        onChange={onLEReading2}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="cyl"
                                        id="cyl"
                                        value={reading2LE.cyl}
                                        onChange={onLEReading2}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="axis"
                                        id="axis"
                                        value={reading2LE.axis}
                                        onChange={onLEReading2}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="addPower"
                                        id="addPower"
                                        value={reading2LE.addPower}
                                        onChange={onLEReading2}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="vA"
                                        id="vA"
                                        value={reading2LE.vA}
                                        onChange={onLEReading2}
                                        type="text"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <input
                                        name="sph"
                                        id="sph"
                                        value={reading3LE.sph}
                                        onChange={onLEReading3}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="cyl"
                                        id="cyl"
                                        value={reading3LE.cyl}
                                        onChange={onLEReading3}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="axis"
                                        id="axis"
                                        value={reading3LE.axis}
                                        onChange={onLEReading3}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="addPower"
                                        id="addPower"
                                        value={reading3LE.addPower}
                                        onChange={onLEReading3}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="vA"
                                        id="vA"
                                        value={reading3LE.vA}
                                        onChange={onLEReading3}
                                        type="text"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <input
                                        name="sph"
                                        id="sph"
                                        value={readingFinalLE.sph}
                                        onChange={onLEReadingFinal}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="cyl"
                                        id="cyl"
                                        value={readingFinalLE.cyl}
                                        onChange={onLEReadingFinal}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="axis"
                                        id="axis"
                                        value={readingFinalLE.axis}
                                        onChange={onLEReadingFinal}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="addPower"
                                        id="addPower"
                                        value={readingFinalLE.addPower}
                                        onChange={onLEReadingFinal}
                                        type="text"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        name="vA"
                                        id="vA"
                                        value={readingFinalLE.vA}
                                        onChange={onLEReadingFinal}
                                        type="text"
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="lg:hidden block flex justify-center flex-col md:flex-row gap-0 md:gap-2">
                      <div className="gap-2 justify-between md:justify-auto flex mb-2">
                        <button
                          onClose={addDrugs}
                          onClick={() => setDrugModal(true)}
                          className="bg-brand-secondary cursor-pointer  float-left text-white font-medium py-2 px-9 md:px-8 rounded text-xs w-36"
                        >
                          Add Medicine
                        </button>
                        <button
                          onClose={addLabTest}
                          onClick={() => setLabModal(true)}
                          className="bg-brand-secondary cursor-pointer float-left text-white font-medium py-2 px-9 md:px-8 rounded text-xs w-36"
                        >
                          Add Lab Test
                        </button>
                      </div>
                      <div className="gap-2 justify-between md:justify-auto  flex mb-4 md:mb-2 ">
                        <button
                          onClick={() => setReferModal(true)}
                          className="bg-brand-secondary cursor-pointer  float-left text-white font-medium py-2 px-12 rounded text-xs w-36"
                        >
                          Refer To
                        </button>
                        <button
                          onClick={saveEPrescription}
                          className={
                            "cursor-pointer text-center bg-brand-secondary  flex float-left text-white font-medium py-2 px-6 rounded text-xs w-36"
                          }
                        >
                          End Consultation
                          {/* qgw{isLoading && <div className="loader float-right ease-linear text-center rounded-full -mt-1 ml-3 border-2 border-t-2 border-gray-200 h-5 w-5"></div>} */}
                        </button>
                      </div>
                    </div>
                    <div className="block lg:hidden mt-3 mb-6">
                      <button
                        onClick={clicktype}
                        className="bg-brand-secondary cursor-pointer  float-center text-white font-medium py-2 px-3 w-full h-25 rounded text-xs"
                      >
                        <p className="hieght-18">
                          {"Scan & Upload Prescription"}
                        </p>
                      </button>

                      <br />
                      <br />
                      {prescriptionDoc ? (
                        <a
                          href={preview}
                          target="_blank"
                          className="font-medium flex"
                        >
                          View Uploaded Prescription &nbsp;
                          {/* <EyeIcon className="h-5  cursor-pointer mt-auto" /> */}
                          <img
                            src={prescription1}
                            className="h-7 cursor-pointer mt-auto"
                            alt="prescription"
                          />
                        </a>
                      ) : null}
                      <input
                        ref={hiddenFileInput}
                        id="filechoose"
                        type="file"
                        accept="application/image;application/pdf"
                        onChange={(e) => {
                          changeHandler(e.target.files[0]);
                        }}
                        className="hidden"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-4/12  ">
                <div className="relative">
                  <div className="lg:block hidden ">
                    <div className="grid">
                      <button
                        onClose={addDrugs}
                        onClick={() => setDrugModal(true)}
                        className="bg-brand-secondary cursor-pointer  float-left text-white font-medium py-2 px-3 w-64 h-25 rounded text-xs"
                      >
                        <div className="flex justify-center mb-2">
                          <img src={pills} />
                        </div>
                        <p className="hieght-18">Add Medicine</p>
                      </button>
                      <br />
                      <button
                        onClose={addLabTest}
                        onClick={() => setLabModal(true)}
                        className="bg-brand-secondary cursor-pointer float-left text-white font-medium py-2 px-3 w-64 h-25 rounded text-xs"
                      >
                        <div className="flex justify-center mb-2">
                          <img src={diagnostics} />
                        </div>
                        <p className="hieght-18">Add Lab Test</p>
                      </button>
                      <br />
                      <button
                        onClick={() => setReferModal(true)}
                        className="bg-brand-secondary cursor-pointer  float-center text-white font-medium py-2 px-3 w-64 h-25 rounded text-xs"
                      >
                        <div className="flex justify-center mb-2">
                          <img src={stethoscope} />
                        </div>
                        <p className="hieght-18">Refer To</p>
                      </button>
                      <br />

                      {editMode ? (
                        <button
                          onClick={updateEPrescription}
                          className={
                            "cursor-pointer bg-brand-secondary float-left text-white font-medium py-2 px-3 w-64 h-25 rounded text-xs"
                          }
                        >
                          <div className="flex justify-center mb-2">
                            <img src={endcall} />
                          </div>
                          <p className="hieght-18">Save</p>
                          {/* qgw{isLoading && <div className="loader float-right ease-linear text-center rounded-full -mt-1 ml-3 border-2 border-t-2 border-gray-200 h-5 w-5"></div>} */}
                        </button>
                      ) : (
                        <button
                          onClick={saveEPrescription}
                          className={
                            "cursor-pointer bg-brand-secondary float-left text-white font-medium py-2 px-3 w-64 h-25 rounded text-xs"
                          }
                        >
                          <div className="flex justify-center mb-2">
                            <img src={endcall} />
                          </div>
                          <p className="hieght-18">End Consultation</p>
                          {/* qgw{isLoading && <div className="loader float-right ease-linear text-center rounded-full -mt-1 ml-3 border-2 border-t-2 border-gray-200 h-5 w-5"></div>} */}
                        </button>
                      )}

                      {/* <button onClick={saveEPrescription} className={"cursor-pointer bg-brand-secondary float-left text-white font-medium py-2 px-3 w-64 h-25 rounded text-xs"}>

                                                <div className="flex justify-center mb-2">
                                                    <img src={endcall} />
                                                </div>
                                                <p className="hieght-18">
                                                    End Consultation
                                                </p>
                                            </button> */}
                      <br />
                      <div className="">
                        <button
                          onClick={clicktype}
                          className="bg-brand-secondary cursor-pointer  float-center text-white font-medium py-2 px-3 w-64 h-25 rounded text-xs"
                        >
                          <p className="hieght-18">
                            {"Scan & Upload Prescription"}
                          </p>
                        </button>

                        <br />
                        <br />
                        {prescriptionDoc ? (
                          <a
                            href={preview}
                            target="_blank"
                            className="font-medium flex"
                          >
                            View Uploaded Prescription &nbsp;
                            {/* <EyeIcon className="h-5  cursor-pointer mt-auto" /> */}
                            <img
                              src={prescription1}
                              className="h-7 cursor-pointer mt-auto"
                              alt="prescription"
                            />
                          </a>
                        ) : null}
                        <input
                          ref={hiddenFileInput}
                          id="filechoose"
                          type="file"
                          accept="application/image;application/pdf"
                          onChange={(e) => {
                            changeHandler(e.target.files[0]);
                          }}
                          className="hidden"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {openLab && (
        <LabModal
          patient={state}
          data={patientLabTestsList}
          onClose={addLabTest}
          open={openLab}
        />
      )}
      {openDrug && (
        <DrugModal
          patient={state}
          data={patientDrugPrescriptionList}
          onClose={addDrugs}
          open={openDrug}
        />
      )}
      {
        openPreview && (
          <Dialog
            header="Prescription Preview"
            visible={true}
            // style={{ width: "25vw" }}
            modal
            className="w-11/12 md:w-9/12 lg:w-8/12"
            onHide={() => setopenPreview(false)}
          >
            <ConsulatationReport
              patient={state}
              data={visitData}
              open={openPreview}
              onClose={() => setopenPreview(false)}
              eyeWearTable={eyeWearUser}
            >
              {" "}
            </ConsulatationReport>
          </Dialog>
        )
        // patient={state} data={visitData} onClose={setopenPreview(false)}
      }
      {openRefer && (
        <ReferModal
          patient={state}
          onClose={() => setReferModal(false)}
          open={openRefer}
        />
      )}
      {showRequestAccess && (
        <RequestAccess
          onClose={closeRequestAccess}
          closePopup={closeRequestAccess}
          requestedAccess={requestedAccess}
          appointmentObj={state}
        ></RequestAccess>
      )}
      {showRequestAccessConsulation && (
        <RequestEndConsultation
          onClose={setShowRequestAccessConsultation(false)}
          closePopup={setShowRequestAccessConsultation(false)}
          requestedAccess={editMode ? updateEPrescription : saveEPrescription}
          appointmentObj={state}
        ></RequestEndConsultation>
      )}
      {share && (
        <Share
          onClose={closeShare}
          closePopup={closeShare}
          data={state}
        ></Share>
      )}
    </>
  );
}
export default VideoCall;
// import React from 'react'

// export default function videoCall() {
//   return (
//     <div>
//       Hiii
//     </div>
//   )
// }
